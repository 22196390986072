import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

import { Logo as BaseLogo } from "./svg/Logo"

const Wrapper = styled.footer`
  padding: 80px;
  background-color: #4a443f;
  display: flex;
  flex-direction: column;
  align-items: center;
`
/*
const Logo = styled(Img)`
  display: block;
  margin-bottom: 24px;
`*/

const Link = styled.a`
  display: block;
  margin-bottom: 12px;
  text-decoration: none;
  font-weight: 500;
  font-size: 25px;
`

const Email = styled(Link)`
  color: #fcbda7;
`

const Phone = styled(Link)`
  color: #f1edea;
`

const Logo = styled(BaseLogo)`
  margin-bottom: 24px;
`

export const Footer = () => {
  return (
    <Wrapper>
      <Logo />
      <Email href="mailto:post@jct-kolseth.no">post@jct-kolseth.no</Email>
      <Phone href="tel:453123456">453123456</Phone>
    </Wrapper>
  )
}
